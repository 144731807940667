import React from "react";
import { injectIntl } from "react-intl";
import startCase from "lodash/startCase";
import Quote from "./Quote";

function PublicationQuote(props) {
  const { publicationType } = props;
  const text = props.intl.formatMessage({
    id: startCase(publicationType) + ".quoteText",
  });
  const author = props.intl.formatMessage({
    id: startCase(publicationType) + ".quoteAuthor",
  });
  return <Quote text={text} author={author} />;
}

export default injectIntl(PublicationQuote);
