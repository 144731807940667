import { SELECT_LOCALE } from "../actionTypes";
import en from "../../translations/en.json";
import ua from "../../translations/ua.json";

const initialState = {
  selectedLocale: "uk-ua",
  locales: {
    en: {
      id: "en",
      name: "English",
      flag: "us",
    },
    "uk-ua": {
      id: "uk-ua",
      name: "Українська",
      flag: "ua",
    },
  },
  messages: {
    en: en,
    "uk-ua": ua,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECT_LOCALE: {
      if (!(action.locale in state.locales)) {
        return state;
      }
      return Object.assign({}, state, {
        selectedLocale: action.locale,
      });
    }

    default:
      return state;
  }
}
