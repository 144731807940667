import React from "react";
import PropTypes from "prop-types";
import BookIcon from "@material-ui/icons/Book";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import InfoIcon from "@material-ui/icons/Info";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import SchoolIcon from "@material-ui/icons/School";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import { BOOKS, ARTICLES, TALKS, LECTURES, GUIDELINES } from "../data/types";

function SectionIcon(props) {
  const { id } = props;
  switch (id) {
    case BOOKS:
      return <BookIcon />;

    case ARTICLES:
      return <ImportContactsIcon />;

    case TALKS:
      return <SlideshowIcon />;

    case LECTURES:
      return <SchoolIcon />;

    case GUIDELINES:
      return <LibraryBooksIcon />;

    default:
      return <InfoIcon />;
  }
}

SectionIcon.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SectionIcon;
