import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { useLocation, Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import connectLocale from "./LocaleAware";
import SectionIcon from "./SectionIcon";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#1b2430",
    },
    "&:visited": {
      backgroundColor: "#1b2430",
    },
    "&:active": {
      backgroundColor: "#1b2430",
    },
  },
  menuItemSelected: {
    color: "#ffffff",
    backgroundColor: "#0e131a",
    "&:hover": {
      backgroundColor: "#0e131a",
    },
    "&:visited": {
      backgroundColor: "#0e131a",
    },
    "&:active": {
      backgroundColor: "#0e131a",
    },
  },
  menuItemIcon: {
    color: "#d8e1e6",
  },
}));

function Navigation(props) {
  const classes = useStyles();
  const { sections } = props;
  const location = useLocation();
  return (
    <List>
      {sections.map((section) => {
        const path = props.pathPrefix + section.id;
        const selected = location.pathname === path;
        return (
          <React.Fragment key={section.id}>
            <MenuItem
              button
              key={section.id}
              component={Link}
              to={path}
              className={selected ? classes.menuItemSelected : classes.menuItem}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <SectionIcon id={section.id} />
              </ListItemIcon>
              <ListItemText
                primary={props.intl.formatMessage({ id: section.title })}
              />
            </MenuItem>
            <Divider />
          </React.Fragment>
        );
      })}
    </List>
  );
}

Navigation.propTypes = {
  sections: PropTypes.array.isRequired,
};

export default connectLocale(injectIntl(Navigation));
