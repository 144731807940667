import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import Columns from "../data/Columns";
import firebase from "../firebase";
import { setLoading } from "../redux/publicationActions";
import { getPublications, isLoading } from "../redux/selectors";
import DeleteConfirmation from "./DeleteConfirmation";
import DownloadLink from "./DownloadLink";
import EditPublication from "./EditPublication";
import PublicationDetails from "./PublicationDetails";
import PublicationQuote from "./PublicationQuote";
import tableIcons from "./TableIcons";

class Publications extends React.PureComponent {
  constructor(props) {
    super(props);
    this.publicationType = props.publicationType;
    this.columns = Columns(props.publicationType);
    this.state = {
      openDelete: false,
      openEdit: false,
      selectedItem: undefined,
    };
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditClose = this.onEditClose.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  onDelete(publication) {
    this.setState({
      openDelete: true,
      selectedItem: publication,
    });
  }

  onDeleteCancel() {
    this.setState({
      openDelete: false,
      selectedItem: undefined,
    });
  }

  onDeleteConfirm() {
    firebase.deleteFile(
      this.state.selectedItem.ref,
      () => {
        this.setState({
          openDelete: false,
          selectedItem: undefined,
        });
      },
      (error) => {
        console.log("failed to delete");
        console.log(error);
      }
    );
  }

  onEditOpen(publication) {
    this.setState({
      openEdit: true,
      selectedItem: publication,
    });
  }

  onEditClose() {
    this.setState({
      openEdit: false,
      selectedItem: undefined,
    });
    this.props.setLoading(this.publicationType);
  }

  getColumns() {
    var columns = this.columns.map((column) => {
      return {
        title: this.props.intl.formatMessage({
          id: column.title,
        }),
        field: column.field,
        searchable: true,
      };
    });
    columns.push({
      title: this.formatMessage("Publications.file"),
      width: 80,
      searchable: false,
      sorting: false,
      render: (rowData) => {
        return <DownloadLink doc={rowData} />;
      },
    });
    return columns;
  }

  getActions() {
    if (!this.props.canEdit) {
      return [];
    }
    return [
      {
        icon: tableIcons.Edit,
        tooltip: this.formatMessage("Publications.edit"),
        onClick: (event, rowData) => {
          event.stopPropagation();
          this.onEditOpen(rowData);
        },
      },
      {
        icon: tableIcons.Delete,
        tooltip: this.formatMessage("Publications.delete"),
        onClick: (event, rowData) => {
          event.stopPropagation();
          this.onDelete(rowData);
        },
      },
      {
        icon: tableIcons.Add,
        tooltip: this.formatMessage("Publications.add"),
        isFreeAction: true,
        onClick: (event) => {
          this.onEditOpen(undefined);
        },
      },
    ];
  }

  getOptions(height) {
    if (isNaN(height) || height < 200) {
      height = 200;
    }
    return {
      actionsColumnIndex: -1,
      draggable: false,
      thirdSortClick: false,
      showTitle: true,
      paging: false,
      maxBodyHeight: height,
      minBodyHeight: height,
      emptyRowsWhenPaging: false,
      headerStyle: {
        fontSize: 18,
      },
      cellStyle: {
        fontSize: 16,
        padding: 10,
      },
    };
  }

  getDetailPanel() {
    return [
      (rowData) => ({
        disabled: !this.canExpandRow(rowData),
        render: (rowData) => {
          return <PublicationDetails row={rowData} />;
        },
      }),
    ];
  }

  canExpandRow(rowData) {
    return rowData.desc !== "" || rowData.imageUrl !== undefined;
  }

  onRowClick(event, rowData, togglePanel) {
    if (this.canExpandRow(rowData)) {
      togglePanel();
    }
  }

  getLocalization() {
    return {
      body: {
        emptyDataSourceMessage: this.formatMessage(
          "Publications.emptyDataSourceMessage"
        ),
      },
      header: {
        actions: "",
      },
      toolbar: {
        searchTooltip: this.formatMessage("Publications.search"),
        searchPlaceholder: this.formatMessage("Publications.search"),
      },
    };
  }

  formatMessage(id) {
    return this.props.intl.formatMessage({
      id: id,
    });
  }

  render() {
    return (
      <React.Fragment>
        <MaterialTable
          title={<PublicationQuote publicationType={this.publicationType} />}
          data={this.props.rows}
          icons={tableIcons}
          columns={this.getColumns()}
          actions={this.getActions()}
          detailPanel={this.getDetailPanel()}
          options={this.getOptions(this.props.height)}
          onRowClick={this.onRowClick}
          localization={this.getLocalization()}
          isLoading={this.props.isLoading}
        />
        {this.props.canEdit && (
          <React.Fragment>
            <DeleteConfirmation
              open={this.state.openDelete}
              selectedItem={this.state.selectedItem}
              onCancel={this.onDeleteCancel}
              onOk={this.onDeleteConfirm}
            />
            <Dialog
              fullScreen
              open={this.state.openEdit}
              onClose={this.onEditClose}
            >
              <EditPublication
                onClose={this.onEditClose}
                publicationType={this.publicationType}
                publication={this.state.selectedItem}
              />
            </Dialog>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

Publications.propTypes = {
  publicationType: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: isLoading(state, ownProps.publicationType),
    rows: getPublications(state, ownProps.publicationType),
    canEdit: state.auth.isSignedIn,
  };
};

export default injectIntl(
  connect(mapStateToProps, { setLoading })(Publications)
);
