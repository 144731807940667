import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Auth from "./Auth";
import Navigation from "./Navigation";
import SelectLocale from "./SelectLocale";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#232f3e",
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerToolbar: {
    width: drawerWidth,
    position: "fixed",
    bottom: 0,
    padding: 0,
    paddingLeft: 6,
  },
}));

function Sidebar(props) {
  const classes = useStyles();
  const { sections } = props;
  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <Navigation sections={sections} />
        <Toolbar className={classes.drawerToolbar}>
          <SelectLocale />
          <Auth />
        </Toolbar>
      </div>
    </Drawer>
  );
}

export default Sidebar;
