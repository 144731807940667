import React from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import connectAuth from "./AuthAware";
import connectLocale from "./LocaleAware";

const styles = (theme) => ({
  icon: {
    color: "#d8e1e6",
  },
});

class Auth extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSignOut = this.onSignOut.bind(this);
  }

  onSignOut() {
    this.props.signOut();
  }

  render() {
    const { classes } = this.props;
    if (this.props.auth.isSignedIn) {
      return (
        <IconButton
          className={classes.icon}
          title={this.props.intl.formatMessage({ id: "Auth.signOut" })}
          onClick={this.onSignOut}
        >
          <ExitToAppIcon />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          className={classes.icon}
          title={this.props.intl.formatMessage({ id: "Auth.signIn" })}
          component={Link}
          to={this.props.pathPrefix + "signin"}
        >
          <LockOpenIcon />
        </IconButton>
      );
    }
  }
}

export default injectIntl(connectLocale(connectAuth(withStyles(styles)(Auth))));
