import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ALL_PUBLICATION_TYPES } from "../data/types";
import connectLocale from "./LocaleAware";
import { buildPathPrefix } from "./LocaleAware";
import TranslationProvider from "./TranslationProvider";
import Main from "./Main";
import SignIn from "./SignIn";

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.validMainPaths = [];
    this.validSignInPaths = [];
    Object.keys(props.locale.locales).forEach((locale) => {
      const pathPrefix = buildPathPrefix(locale);
      ALL_PUBLICATION_TYPES.forEach((publicationType) => {
        this.validMainPaths.push(pathPrefix + publicationType);
      });
      this.validSignInPaths.push(pathPrefix + "signin");
    });
  }

  render() {
    return (
      <BrowserRouter>
        <TranslationProvider>
          <Switch>
            <Route exact path={this.validSignInPaths}>
              <SignIn />
            </Route>
            <Route exact path={this.validMainPaths}>
              <Main />
            </Route>
            <Route>
              <Redirect to={this.props.pathPrefix + ALL_PUBLICATION_TYPES[0]} />
            </Route>
          </Switch>
        </TranslationProvider>
      </BrowserRouter>
    );
  }
}

export default connectLocale(App);
