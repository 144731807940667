import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  details: {
    backgroundColor: "#eceff1",
  },
}));

function PublicationDetails(props) {
  const { row } = props;
  const classes = useStyles();
  return (
    <Box p={3} className={classes.details}>
      <Grid container direction="row" alignItems="flex-start" spacing={3}>
        {row.imageUrl !== undefined && (
          <Grid item>
            <img width="160" height="250" src={row.imageUrl} alt="cover" />
          </Grid>
        )}
        <Grid item xs>
          <Typography component="div" align="justify">
            {row.desc}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

PublicationDetails.propTypes = {
  row: PropTypes.object.isRequired,
};

export default PublicationDetails;
