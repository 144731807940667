class BasePublication {
  constructor(id, title, authors, year, ref, url, desc, size) {
    this.id = id;
    this.title = title;
    this.authors = authors;
    this.authorsAsString = authors.join(", ");
    this.year = year;
    this.ref = ref;
    this.url = url;
    this.desc = desc;
    this.size = size;
  }
}

export default BasePublication;
