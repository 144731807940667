import React from "react";
import { injectIntl } from "react-intl";
import { withRouter, Link } from "react-router-dom";
import Flag from "react-world-flags";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import connectLocale from "./LocaleAware";
import { buildPathPrefix } from "./LocaleAware";

class SelectLocale extends React.PureComponent {
  constructor(props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.changeLocale = this.changeLocale.bind(this);
    this.state = {
      menuAnchor: null,
    };
  }

  render() {
    const path = this.props.location.pathname.split("/");
    return (
      <React.Fragment>
        <IconButton
          title={this.props.intl.formatMessage({
            id: "SelectLocale.changeLanguage",
          })}
          onClick={this.openMenu}
        >
          <Flag
            code={
              this.props.locale.locales[this.props.locale.selectedLocale].flag
            }
            height="16"
          />
        </IconButton>
        <Menu
          onClose={this.closeMenu}
          open={Boolean(this.state.menuAnchor)}
          anchorEl={this.state.menuAnchor}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {Object.keys(this.props.locale.locales).map((id) => {
            const locale = this.props.locale.locales[id];
            return (
              <MenuItem
                key={locale.id}
                component={Link}
                to={buildPathPrefix(locale.id) + path[path.length - 1]}
                selected={this.props.locale.selectedLocale === locale.id}
                onClick={this.closeMenu}
              >
                <Flag code={locale.flag} height="16" />
                <Box pl={1}>
                  <Typography>{locale.name}</Typography>
                </Box>
              </MenuItem>
            );
          })}
        </Menu>
      </React.Fragment>
    );
  }

  openMenu(event) {
    this.setState({
      menuAnchor: event.currentTarget,
    });
  }

  closeMenu() {
    this.setState({
      menuAnchor: null,
    });
  }

  changeLocale(locale) {
    if (locale !== this.props.locale.selectedLocale) {
      this.props.selectLocale(locale);
    }
    this.closeMenu();
  }
}

export default withRouter(injectIntl(connectLocale(SelectLocale)));
