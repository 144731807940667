import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import connectLocale from "./LocaleAware";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
    textDecoration: "none",
  },
  appBar: {
    backgroundColor: "#283593",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function Header(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Box pr={2}>
            <Avatar src="/logo.png" />
          </Box>
          <Typography
            component={Link}
            to={props.pathPrefix}
            variant="h5"
            color="inherit"
            align="left"
            noWrap
            className={classes.toolbarTitle}
          >
            <FormattedMessage id="Header.title" />
          </Typography>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default connectLocale(Header);
