import React from "react";
import { withResizeDetector } from "react-resize-detector";
import { Route, Switch } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import Publications from "./Publications";
import Sidebar from "./Sidebar";
import { BOOKS, ARTICLES, TALKS, LECTURES, GUIDELINES } from "../data/types";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    padding: 0,
  },
  content: {
    flexGrow: 1,
    minWidth: 500,
  },
}));

const sections = [
  { title: "Books.title", id: BOOKS },
  { title: "Articles.title", id: ARTICLES },
  { title: "Talks.title", id: TALKS },
  { title: "Lectures.title", id: LECTURES },
  { title: "Guidelines.title", id: GUIDELINES },
];

const topPartHeight = 216;

function Main(props) {
  const { height } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <Container maxWidth="xl" className={classes.root}>
        <Header />
        <Divider />
        <Sidebar sections={sections} />
        <main className={classes.content}>
          <Toolbar />
          <Switch>
            {sections.map((section) => (
              <Route key={section.id} path={"/*/" + section.id}>
                <Publications
                  publicationType={section.id}
                  height={height - topPartHeight}
                />
              </Route>
            ))}
          </Switch>
        </main>
      </Container>
    </React.Fragment>
  );
}

export default withResizeDetector(Main);
