export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const AUTH_ERROR = "AUTH_ERROR";

export const ADD_PUBLICATION = "ADD_PUBLICATION";
export const DELETE_PUBLICATION = "DELETE_PUBLICATION";
export const UPDATE_PUBLICATION = "UPDATE_PUBLICATION";
export const SET_LOADING = "SET_LOADING";

export const SELECT_LOCALE = "SELECT_LOCALE";
