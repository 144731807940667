export const BOOKS = "books";
export const ARTICLES = "articles";
export const TALKS = "talks";
export const LECTURES = "lectures";
export const GUIDELINES = "guidelines";

export const ALL_PUBLICATION_TYPES = [
  BOOKS,
  ARTICLES,
  TALKS,
  LECTURES,
  GUIDELINES,
];
