import { ARTICLES, BOOKS, GUIDELINES, LECTURES, TALKS } from "./types";

class Column {
  constructor(title, field) {
    this.title = title;
    this.field = field;
  }
}

function Columns(type) {
  switch (type) {
    case ARTICLES:
      return [
        new Column("Publications.title", "title"),
        new Column("Publications.authors", "authorsAsString"),
        new Column("Publications.publishedIn", "publishedIn"),
        new Column("Publications.year", "year"),
      ];

    case BOOKS:
      return [
        new Column("Publications.title", "title"),
        new Column("Publications.authors", "authorsAsString"),
        new Column("Publications.year", "year"),
      ];

    case GUIDELINES:
      return [
        new Column("Publications.title", "title"),
        new Column("Publications.authors", "authorsAsString"),
        new Column("Publications.year", "year"),
      ];

    case LECTURES:
      return [
        new Column("Publications.title", "title"),
        new Column("Publications.authors", "authorsAsString"),
        new Column("Publications.year", "year"),
      ];

    case TALKS:
      return [
        new Column("Publications.title", "title"),
        new Column("Publications.authors", "authorsAsString"),
        new Column("Publications.event", "event"),
        new Column("Publications.year", "year"),
      ];

    default:
      return [];
  }
}

export default Columns;
