export const getPublications = (state, publicationType) => {
  const publications = state.publications[publicationType];
  if (publications === undefined) {
    return [];
  }
  return publications.allIds.map((id) => publications.byId[id]);
};

export const isLoading = (state, publicationType) => {
  const publications = state.publications[publicationType];
  if (publications === undefined) {
    return true;
  }
  return publications.isLoading;
};
