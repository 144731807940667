import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { v4 as uuid } from "uuid";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ARTICLES, TALKS } from "../data/types";
import firebase from "../firebase";
import Header from "./Header";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
});

class EditPublication extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onFileChange = this.onFileChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onClose = props.onClose;
    this.file = React.createRef();
    this.showPublishedIn = props.publicationType === ARTICLES;
    this.showEvent = props.publicationType === TALKS;
    this.state = {
      errorMessage: null,
      fileName: props.publication?.ref.split("/").pop() ?? "",
      title: props.publication?.title ?? "",
      authors: props.publication?.authors.join(", ") ?? "",
      publishedIn: props.publication?.publishedIn ?? "",
      event: props.publication?.event ?? "",
      year: props.publication?.year.toString() ?? "",
      desc: props.publication?.desc ?? "",
      publicationId: props.publication?.id,
      publicationType: props.publicationType,
      waiting: false,
      progress: 0,
    };
  }

  onFileChange(props) {
    this.setState({
      fileName: this.getSelectedFileName(),
    });
  }

  getSelectedFileName() {
    var files = this.file.current.files;
    return files.length > 0 ? files[0].name : null;
  }

  onSave() {
    this.setState({
      waiting: true,
    });
    var error = this.validate();
    if (error != null) {
      this.setState({
        errorMessage: error,
      });
      return;
    }

    const publicationId = this.getPublicationId();
    const metadata = this.getMetadata(publicationId);
    const filePath = this.getFilePath();

    if (this.file.current.files.length === 0) {
      firebase.updateMetadata(filePath, metadata, this.onSuccess, this.onError);
    } else {
      firebase.uploadFile(
        filePath,
        this.file.current.files[0],
        metadata,
        this.onProgress,
        this.onSuccess,
        this.onError
      );
    }
  }

  onProgress(progress) {
    this.setState({
      progress: progress,
    });
  }

  onSuccess() {
    this.onClose();
  }

  onError(error) {
    this.setState({
      errorMessage: error.message,
    });
  }

  validate() {
    if (this.getTitle().length === 0) {
      return "Title is required";
    }
    if (this.getAuthors().length === 0) {
      return "Authors are required";
    }
    var year = parseInt(this.getYear());
    if (isNaN(year) || year < 1900) {
      return "Valid year is required";
    }
    if (
      this.state.publicationId === undefined &&
      this.file.current.files.length === 0
    ) {
      return "File is required for a new publication";
    }
    return null;
  }

  getMetadata(publicationId) {
    var metadata = {
      title: this.getTitle(),
      authors: this.getAuthors().join(","),
      year: this.getYear(),
      desc: this.getDesc(),
      collection: this.state.publicationType,
      doc: publicationId,
    };
    if (this.showPublishedIn) {
      metadata.publishedIn = this.getPublishedIn();
    }
    if (this.showEvent) {
      metadata.event = this.getEvent();
    }
    return metadata;
  }

  getFilePath() {
    return this.state.publicationType + "/" + this.state.fileName;
  }

  getPublicationId() {
    if (this.state.publicationId !== undefined) {
      return this.state.publicationId;
    }
    return uuid();
  }

  getTitle() {
    return this.state.title.trim();
  }

  getAuthors() {
    var authors = this.state.authors
      .trim()
      .split(",")
      .map((element) => element.trim())
      .filter((element) => element !== "");
    return authors.filter((element) => element.length > 0);
  }

  getPublishedIn() {
    return this.state.publishedIn.trim();
  }

  getEvent() {
    return this.state.event.trim();
  }

  getYear() {
    return this.state.year.trim();
  }

  getDesc() {
    return this.state.desc.trim();
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <DialogContent>
          <Container component="main" maxWidth="md">
            <Header></Header>
            <Backdrop open={this.state.waiting} className={classes.backdrop}>
              <Box position="relative" display="inline-flex">
                <CircularProgress size={120} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="h4" component="div">
                    {this.state.progress}%
                  </Typography>
                </Box>
              </Box>
            </Backdrop>
            <div className={classes.paper}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="title"
                label={this.props.intl.formatMessage({
                  id: "EditPublication.title",
                })}
                name="title"
                value={this.state.title}
                onChange={(event) => {
                  this.setState({
                    title: event.target.value,
                  });
                }}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="authors"
                label={this.props.intl.formatMessage({
                  id: "EditPublication.authors",
                })}
                name="authors"
                value={this.state.authors}
                onChange={(event) => {
                  this.setState({
                    authors: event.target.value,
                  });
                }}
              />
              {this.showPublishedIn && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="publishedIn"
                  label={this.props.intl.formatMessage({
                    id: "EditPublication.publishedIn",
                  })}
                  name="publishedIn"
                  value={this.state.publishedIn}
                  onChange={(event) => {
                    this.setState({
                      publishedIn: event.target.value,
                    });
                  }}
                />
              )}
              {this.showEvent && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="event"
                  label={this.props.intl.formatMessage({
                    id: "EditPublication.event",
                  })}
                  name="event"
                  value={this.state.event}
                  onChange={(event) => {
                    this.setState({
                      event: event.target.value,
                    });
                  }}
                />
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="year"
                label={this.props.intl.formatMessage({
                  id: "EditPublication.year",
                })}
                name="year"
                type="number"
                value={this.state.year}
                onChange={(event) => {
                  this.setState({
                    year: event.target.value,
                  });
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                rows={5}
                id="desc"
                label={this.props.intl.formatMessage({
                  id: "EditPublication.desc",
                })}
                name="desc"
                value={this.state.desc}
                onChange={(event) => {
                  this.setState({
                    desc: event.target.value,
                  });
                }}
              />
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={10}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={this.props.intl.formatMessage({
                      id: "EditPublication.file",
                    })}
                    value={this.state.fileName}
                    disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button fullWidth variant="contained" component="label">
                    <FormattedMessage id="EditPublication.upload" />
                    <input
                      type="file"
                      onChange={this.onFileChange}
                      ref={this.file}
                      style={{ display: "none" }}
                    />
                  </Button>
                </Grid>
              </Grid>
              {this.state.errorMessage !== null && (
                <Alert severity="error" elevation={6}>
                  {this.state.errorMessage}
                </Alert>
              )}
              <Grid container direction="row" justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    onClick={this.onSave}
                    variant="contained"
                    color="primary"
                  >
                    <FormattedMessage id="EditPublication.save" />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={this.onClose}
                    variant="contained"
                    color="primary"
                  >
                    <FormattedMessage id="EditPublication.cancel" />
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </DialogContent>
      </React.Fragment>
    );
  }
}

export default injectIntl(withStyles(styles)(EditPublication));
