import omitBy from "lodash/omitBy";
import {
  UPDATE_PUBLICATION,
  ADD_PUBLICATION,
  DELETE_PUBLICATION,
  SET_LOADING,
} from "../actionTypes";

function publications(
  state = {
    isLoading: false,
    byId: {},
    allIds: [],
  },
  action
) {
  switch (action.type) {
    case SET_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      });

    case ADD_PUBLICATION:
      const ids = state.allIds.concat(action.publication.id);
      return Object.assign({}, state, {
        allIds: fixOrder(ids, ids.length - 1, action.newIndex),
        byId: Object.assign({}, state.byId, {
          [action.publication.id]: action.publication,
        }),
      });

    case UPDATE_PUBLICATION:
      return Object.assign({}, state, {
        allIds: fixOrder(state.allIds, action.oldIndex, action.newIndex),
        byId: Object.assign({}, state.byId, {
          [action.publication.id]: action.publication,
        }),
      });

    case DELETE_PUBLICATION:
      return Object.assign({}, state, {
        allIds: state.allIds.filter((item) => item !== action.publication.id),
        byId: omitBy(state.byId, (value, key) => key === action.publication.id),
      });

    default:
      return state;
  }
}

function fixOrder(ids, oldIndex, newIndex) {
  if (oldIndex === newIndex) {
    return ids;
  }
  if (oldIndex < newIndex) {
    return ids
      .slice(0, oldIndex)
      .concat(ids.slice(oldIndex + 1, newIndex + 1))
      .concat([ids[oldIndex]])
      .concat(ids.slice(newIndex + 1, ids.length));
  }
  return ids
    .slice(0, newIndex)
    .concat([ids[oldIndex]])
    .concat(ids.slice(newIndex, oldIndex))
    .concat(ids.slice(oldIndex + 1, ids.length));
}

export default function publicationsByType(state = {}, action) {
  switch (action.type) {
    case SET_LOADING:
    case ADD_PUBLICATION:
    case UPDATE_PUBLICATION:
    case DELETE_PUBLICATION:
      return Object.assign({}, state, {
        [action.publicationType]: publications(
          state[action.publicationType],
          action
        ),
      });
    default:
      return state;
  }
}
