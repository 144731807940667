import React from "react";
import { IntlProvider } from "react-intl";
import { withRouter } from "react-router-dom";
import { selectLocale } from "../redux/localeActions";
import connectLocale from "./LocaleAware";

export class TranslationProvider extends React.PureComponent {
  componentDidMount() {
    this.updateLocaleFromLocation(this.props.location);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location !== this.props.location) {
      this.updateLocaleFromLocation(this.props.location);
    }
  }

  updateLocaleFromLocation(location) {
    const parts = location.pathname.split("/").filter((part) => part !== "");
    if (parts.length > 0) {
      this.props.selectLocale(parts[0]);
    }
  }

  render() {
    const locale = this.props.locale.selectedLocale;
    return (
      <IntlProvider
        locale={locale}
        messages={this.props.locale.messages[locale]}
      >
        {this.props.children}
      </IntlProvider>
    );
  }
}

export default withRouter(connectLocale(TranslationProvider, { selectLocale }));
