import { SELECT_LOCALE } from "./actionTypes";

function selectLocaleAction(locale) {
  return {
    type: SELECT_LOCALE,
    locale: locale,
  };
}

export function selectLocale(locale) {
  return (dispatch) => {
    dispatch(selectLocaleAction(locale));
  };
}
