import BasePublication from "./BasePublication";

class Talk extends BasePublication {
  constructor(id, title, authors, year, ref, url, desc, size, event) {
    super(id, title, authors, year, ref, url, desc, size);
    this.event = event;
  }
}

const talkConverter = {
  toFirestore: function (talk) {
    return {
      title: talk.title,
      authors: talk.authors,
      year: talk.year,
      ref: talk.ref,
      url: talk.url,
      desc: talk.desc,
      size: talk.size,
      event: talk.event,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return new Talk(
      snapshot.id,
      data.title,
      data.authors,
      data.year,
      data.ref,
      data.url,
      data.desc,
      data.size,
      data.event
    );
  },
};

export default talkConverter;
