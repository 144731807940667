import app from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.db = app.firestore();
    this.auth = app.auth();
    this.storage = app.storage();
    this.analytics = app.analytics();
    app.firestore().settings({
      cacheSizeBytes: app.firestore.CACHE_SIZE_UNLIMITED,
    });
    app.firestore().enablePersistence({
      synchronizeTabs: true,
    });
  }

  getDoc(collectionName, id) {
    return this.db.collection(collectionName).doc(id).get();
  }

  uploadFile(filePath, file, customMetadata, onProgress, onSuccess, onError) {
    var metadata = this.wrapCustomMetadata(customMetadata);
    var ref = this.getRef(filePath);
    ref.put(file, metadata).on(
      app.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        onProgress(progress);
      },
      onError,
      onSuccess
    );
  }

  deleteFile(filePath, onSuccess, onError) {
    this.getRef(filePath).delete().then(onSuccess, onError);
  }

  updateMetadata(filePath, customMetadata, onSuccess, onError) {
    var ref = this.getRef(filePath);
    ref
      .updateMetadata(this.wrapCustomMetadata(customMetadata))
      .then(onSuccess, onError);
  }

  getRef(filePath) {
    return this.storage.ref().child(filePath);
  }

  wrapCustomMetadata(customMetadata) {
    return {
      customMetadata: customMetadata,
    };
  }
}

export default Firebase;
