import BasePublication from "./BasePublication";

class Article extends BasePublication {
  constructor(id, title, authors, year, ref, url, desc, size, publishedIn) {
    super(id, title, authors, year, ref, url, desc, size);
    this.publishedIn = publishedIn;
  }
}

const articleConverter = {
  toFirestore: function (article) {
    return {
      title: article.title,
      authors: article.authors,
      year: article.year,
      ref: article.ref,
      url: article.url,
      desc: article.desc,
      size: article.size,
      publishedIn: article.publishedIn,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return new Article(
      snapshot.id,
      data.title,
      data.authors,
      data.year,
      data.ref,
      data.url,
      data.desc,
      data.size,
      data.publishedIn
    );
  },
};

export default articleConverter;
