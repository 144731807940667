import {
  ADD_PUBLICATION,
  UPDATE_PUBLICATION,
  DELETE_PUBLICATION,
  SET_LOADING,
} from "./actionTypes";
import firebase from "../firebase";

function setLoadingAction(publicationType, isLoading) {
  return {
    type: SET_LOADING,
    publicationType: publicationType,
    isLoading: isLoading,
  };
}

function publicationAction(publicationType, actionType, docChange) {
  return {
    type: actionType,
    publicationType: publicationType,
    publication: docChange.doc.data(),
    oldIndex: docChange.oldIndex,
    newIndex: docChange.newIndex,
  };
}

export function setLoading(publicationType) {
  return (dispatch) => {
    dispatch(setLoadingAction(publicationType, true));
  };
}

export function loadPublications(publicationType, converter) {
  return (dispatch) => {
    dispatch(setLoadingAction(publicationType, true));
    firebase.db
      .collection(publicationType)
      .withConverter(converter)
      .where("tombstone", "==", false)
      .orderBy("title", "asc")
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((docChange) => {
          switch (docChange.type) {
            case "added":
              dispatch(
                publicationAction(publicationType, ADD_PUBLICATION, docChange)
              );
              return;

            case "modified":
              dispatch(
                publicationAction(
                  publicationType,
                  UPDATE_PUBLICATION,
                  docChange
                )
              );
              return;

            case "removed":
              dispatch(
                publicationAction(
                  publicationType,
                  DELETE_PUBLICATION,
                  docChange
                )
              );
              return;

            default:
              return;
          }
        });
        dispatch(setLoadingAction(publicationType, false));
      });
  };
}
