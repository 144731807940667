import React from "react";
import { withResizeDetector } from "react-resize-detector";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderColor: "rgba(66, 165, 245, 1.)",
    backgroundColor: "rgba(66, 165, 245, .2)",
    backgroundRepeat: "no-repeat",
    backgroundImage:
      "url(" +
      '\'data:image/svg+xml;utf8,<svg viewBox="3 3 18 18" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="rgba(66, 165, 245, .3)" ><path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z"/></g></svg>\'' +
      ")",
  },
}));

function QuotePaper(props) {
  const { height } = props;
  const classes = useStyles();
  return (
    <Paper
      className={classes.paper}
      variant="outlined"
      style={{
        backgroundSize: height,
      }}
    >
      {props.children}
    </Paper>
  );
}

export default withResizeDetector(QuotePaper);
