import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import QuotePaper from "./QuotePaper";

const useStyles = makeStyles((theme) => ({
  main: {
    maxHeight: 112,
    minHeight: 112,
  },
}));

function Quote(props) {
  const classes = useStyles();
  return (
    <Box paddingY={2} marginBottom={1} className={classes.main}>
      <QuotePaper>
        <Box display="flex" flexWrap="nowrap" alignItems="center">
          <Box margin={2} width="100%">
            <Typography component="div">
              <Box fontStyle="italic" fontWeight="bold">
                {props.text}
              </Box>
            </Typography>
            <Typography variant="caption">
              <Box textAlign="right">{props.author}</Box>
            </Typography>
          </Box>
        </Box>
      </QuotePaper>
    </Box>
  );
}

export default Quote;
