import { SIGN_IN, SIGN_OUT, AUTH_ERROR } from "./actionTypes";
import firebase from "../firebase";

function signInAction() {
  return {
    type: SIGN_IN,
  };
}

function signOutAction() {
  return {
    type: SIGN_OUT,
  };
}

function authErrorAction(error) {
  return {
    type: AUTH_ERROR,
    payload: {
      error: error,
    },
  };
}

export function signIn(email, password) {
  return (dispatch) => {
    return firebase.auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        dispatch(signInAction());
      })
      .catch((error) => {
        dispatch(authErrorAction(error));
      });
  };
}

export function signOut() {
  return (dispatch) => {
    return firebase.auth
      .signOut()
      .then(() => {
        dispatch(signOutAction());
      })
      .catch((error) => {
        dispatch(authErrorAction(error));
      });
  };
}

export function loadAuthState() {
  return (dispatch) => {
    const unregister = firebase.auth.onAuthStateChanged(function (user) {
      if (user) {
        dispatch(signInAction());
      }
      unregister();
    });
  };
}
