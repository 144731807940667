import { connect as reduxConnect } from "react-redux";
import { signIn, signOut } from "../redux/authActions";

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default function connect(Component) {
  return reduxConnect(mapStateToProps, { signIn, signOut })(Component);
}
