import { loadAuthState } from "./redux/authActions";
import { loadPublications } from "./redux/publicationActions";
import { ALL_PUBLICATION_TYPES } from "./data/types";
import DataConverter from "./data/Data";
import store from "./redux/store";

export default function () {
  store.dispatch(loadAuthState());
  ALL_PUBLICATION_TYPES.forEach((publicationType) => {
    store.dispatch(
      loadPublications(publicationType, DataConverter(publicationType))
    );
  });
}
