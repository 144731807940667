import { AUTH_ERROR, SIGN_IN, SIGN_OUT } from "../actionTypes";

const initialState = {
  isSignedIn: undefined,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGN_IN: {
      return {
        isSignedIn: true,
        error: null,
      };
    }

    case SIGN_OUT: {
      return {
        isSignedOut: false,
        error: null,
      };
    }

    case AUTH_ERROR: {
      return {
        isSignedIn: state.isSignedIn,
        error: action.payload.error,
      };
    }

    default:
      return state;
  }
}
