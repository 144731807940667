import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

class DeleteConfirmation extends React.PureComponent {
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.onCancel}>
        <DialogTitle>
          <FormattedMessage id="DeleteConfirmation.title" />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id="DeleteConfirmation.content"
            values={{ title: this.props.selectedItem?.title }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onOk}>
            <FormattedMessage id="DeleteConfirmation.ok" />
          </Button>
          <Button onClick={this.props.onCancel}>
            <FormattedMessage id="DeleteConfirmation.cancel" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DeleteConfirmation;
