import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import connectAuth from "./AuthAware";
import Header from "./Header";
import connectLocale from "./LocaleAware";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(24),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignIn extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSignIn = this.onSignIn.bind(this);
  }

  onSignIn() {
    this.props.signIn(this.email.value, this.password.value);
  }

  render() {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        {this.props.auth.isSignedIn && <Redirect to={this.props.pathPrefix} />}
        <Header></Header>
        <Divider />
        <div className={classes.paper}>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={this.props.intl.formatMessage({ id: "SignIn.email" })}
              name="email"
              autoComplete="email"
              inputRef={(el) => (this.email = el)}
              autoFocus
            ></TextField>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={this.props.intl.formatMessage({ id: "SignIn.password" })}
              type="password"
              id="password"
              inputRef={(el) => (this.password = el)}
              autoComplete="current-password"
            ></TextField>
            <Button
              onClick={this.onSignIn}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              <FormattedMessage id="SignIn.signIn" />
            </Button>
            {this.props.auth.error !== null && (
              <Alert severity="error" elevation={6}>
                <FormattedMessage
                  id="SignIn.error"
                  values={{ error: this.props.auth.error.message }}
                />
              </Alert>
            )}
          </form>
        </div>
      </Container>
    );
  }
}

export default injectIntl(
  connectLocale(connectAuth(withStyles(styles)(SignIn)))
);
