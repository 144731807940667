import articleConverter from "./Article";
import bookConverter from "./Book";
import guidelineConverter from "./Guideline";
import lectureConverter from "./Lecture";
import talkConverter from "./Talk";
import { BOOKS, ARTICLES, TALKS, LECTURES, GUIDELINES } from "./types";

function DataConverter(type) {
  switch (type) {
    case ARTICLES:
      return articleConverter;

    case BOOKS:
      return bookConverter;

    case GUIDELINES:
      return guidelineConverter;

    case LECTURES:
      return lectureConverter;

    case TALKS:
      return talkConverter;

    default:
      return null;
  }
}

export default DataConverter;
