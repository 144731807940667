import { connect as reduxConnect } from "react-redux";

export function buildPathPrefix(locale) {
  return "/" + locale + "/";
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    pathPrefix: buildPathPrefix(state.locale.selectedLocale),
  };
};

export default function connect(Component, mapDispatchToProps) {
  return reduxConnect(mapStateToProps, mapDispatchToProps)(Component);
}
