import BasePublication from "./BasePublication";

class Guideline extends BasePublication {}

const guidelineConverter = {
  toFirestore: function (guideline) {
    return {
      title: guideline.title,
      authors: guideline.authors,
      year: guideline.year,
      ref: guideline.ref,
      url: guideline.url,
      desc: guideline.desc,
      size: guideline.size,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return new Guideline(
      snapshot.id,
      data.title,
      data.authors,
      data.year,
      data.ref,
      data.url,
      data.desc,
      data.size
    );
  },
};

export default guidelineConverter;
