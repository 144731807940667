import BasePublication from "./BasePublication";

class Lecture extends BasePublication {}

const lectureConverter = {
  toFirestore: function (lecture) {
    return {
      title: lecture.title,
      authors: lecture.authors,
      year: lecture.year,
      ref: lecture.ref,
      url: lecture.url,
      desc: lecture.desc,
      size: lecture.size,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return new Lecture(
      snapshot.id,
      data.title,
      data.authors,
      data.year,
      data.ref,
      data.url,
      data.desc,
      data.size
    );
  },
};

export default lectureConverter;
