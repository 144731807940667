import React from "react";
import filesize from "filesize";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

class DownloadLink extends React.PureComponent {
  render() {
    return (
      <Link
        href={this.props.doc.url}
        target="_blank"
        onClick={(event) => event.stopPropagation()}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CloudDownloadIcon />
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {filesize(this.props.doc.size, { round: 0 })}
            </Typography>
          </Grid>
        </Grid>
      </Link>
    );
  }
}

export default DownloadLink;
