import BasePublication from "./BasePublication";

class Book extends BasePublication {
  constructor(id, title, authors, year, ref, url, desc, size, imageUrl) {
    super(id, title, authors, year, ref, url, desc, size);
    this.imageUrl = imageUrl;
  }
}

const bookConverter = {
  toFirestore: function (book) {
    return {
      title: book.title,
      authors: book.authors,
      year: book.year,
      ref: book.ref,
      url: book.url,
      desc: book.desc,
      size: book.size,
      imageUrl: book.imageUrl,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return new Book(
      snapshot.id,
      data.title,
      data.authors,
      data.year,
      data.ref,
      data.url,
      data.desc,
      data.size,
      data.imageUrl
    );
  },
};

export default bookConverter;
